//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class NotFoundPage extends React.Component {
  render() {
    return (
      <div>
        <h1>Not found</h1>
        <p>Please go back to the home page.</p>
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default NotFoundPage;
